.auth-sign-in-view .form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.auth-sign-in-view .form-signin .checkbox {
  font-weight: 400;
}
.auth-sign-in-view .form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.auth-sign-in-view .form-signin .form-control:focus {
  z-index: 2;
}
.auth-sign-in-view .form-signin input[type="text"] {
  /* margin-bottom: -1px; */
  /* border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; */
}
.auth-sign-in-view .form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}