#page-root-container {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    max-width: 100vw;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
}

#page-sidebar-menu {
    max-width: 250px;
    min-width: 250px;
    width: 250px;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

#page-main-container {
    flex: 1;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    /* width: 100%; */
    /* max-width: 100%; */
}

.side-bar {
    /* max-width: 250px; */
    /* min-width: 250px; */
    /* width: 250px; */
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.accordion-button:focus, #new-message-input.form-control:focus {
    box-shadow: none;
}
.accordion-button:not(.collapsed) {
    color: inherit;
}

.toggle-button {
    position: fixed;
    top: 12px;
    right: 12px;
    z-index: 9999;
}